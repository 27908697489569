import { SvgIcon, createSvgIcon } from "@mui/material";

const SixEightIcon = createSvgIcon(
  <SvgIcon viewBox="0 0 11.35 30">
    <path d="M11.1,8.94c-.69-1.86-2.83-2.72-4.68-2.57a7,7,0,0,0-1.55.26c-.18.06-.89.49-1.09.32s0-1.48,0-1.79a8.39,8.39,0,0,1,.85-2.78,1.76,1.76,0,0,1,1.06-1c.29-.09.8-.13,1,.13s-.13.61-.25.9a2.58,2.58,0,0,0-.11,1.41A2.17,2.17,0,0,0,8.45,5.45a2.26,2.26,0,0,0,2-1.59,2.75,2.75,0,0,0-.91-2.78A5.41,5.41,0,0,0,6.41,0,6.06,6.06,0,0,0,3.32.85,6.9,6.9,0,0,0,.1,6.33,8.5,8.5,0,0,0,1.61,12.5,5.69,5.69,0,0,0,8,14.15C10,13.41,11.94,11.21,11.1,8.94ZM7.56,11.39a2.47,2.47,0,0,1-.85,1.71A1.42,1.42,0,0,1,5,13.1c-1-.82-1-2.6-.81-3.76A2,2,0,0,1,4.82,8a1.92,1.92,0,0,1,1.64-.29C7.72,8.15,7.71,10.32,7.56,11.39Z" />
    <path d="M11.06,24.31a4.18,4.18,0,0,0-1.54-1.74c-.07,0-.58-.29-.58-.37s.58-.54.71-.67c1.37-1.32,1.87-3.7.11-4.94a7.92,7.92,0,0,0-5.36-1C2.6,15.8.54,16.86.31,18.87A3.34,3.34,0,0,0,1,21.47,9.72,9.72,0,0,0,3,22.8a4.52,4.52,0,0,0-.89.43A5.11,5.11,0,0,0,1,24a3.42,3.42,0,0,0-1,2.42c0,1.92,1.93,3,3.6,3.33A8,8,0,0,0,9.37,29,3.88,3.88,0,0,0,11.06,24.31ZM8.19,27.49c-.66,2-3.73,1.76-5,.51a2.34,2.34,0,0,1-.44-2.77c.22-.46.91-1.8,1.57-1.67h0a5.25,5.25,0,0,1,1.36.66A14,14,0,0,1,7,25,2.28,2.28,0,0,1,8.19,27.49Zm.38-8a3.51,3.51,0,0,1-1,1.59c-.39.36-.74.2-1.17,0-1.09-.5-2.83-1.39-3.13-2.67-.39-1.65,1.89-1.74,2.94-1.59C7.67,17,9,17.89,8.57,19.52Z" />
  </SvgIcon>
);

export default SixEightIcon;
