import { SvgIcon, createSvgIcon } from "@mui/material";

const ThreeFourIcon = createSvgIcon(
  <SvgIcon viewBox="0 0 11.34 30">
    <path d="M4,2a1.92,1.92,0,0,1,.69,2.34,2,2,0,0,1-2.08,1A1.93,1.93,0,0,1,.87,3.89,2.72,2.72,0,0,1,1.25,1.6C2.4.15,4.74-.1,6.44,0s3.69.81,4.45,2.6a2.64,2.64,0,0,1-.41,2.75,3.72,3.72,0,0,1-.95.75c-.58.34-1,.44-1.13.66a5.67,5.67,0,0,1,2.23,1.43,3.27,3.27,0,0,1,.65,2.67c-.29,2-2.51,3.28-4.38,3.54a7.17,7.17,0,0,1-5.4-1.29C.2,12,0,9.54,2,9a2.09,2.09,0,0,1,2.61,1.07,2.45,2.45,0,0,1,0,1.64c-.17.38-.67.78-.47,1.24,1.78,1.06,3.66-.66,3.7-2.5A2.86,2.86,0,0,0,6.65,8.21a5.28,5.28,0,0,0-2.9-.85l0-1.09a5,5,0,0,0,2.87-.71,2.63,2.63,0,0,0,.62-2.82,2,2,0,0,0-2-1.62C4.8,1.1,3.22,1.27,4,2Z" />
    <path d="M0,26.28V25.1a11.94,11.94,0,0,0,2.2-3.24,24.33,24.33,0,0,0,1.3-4.11c.16-.73.26-1.47.35-2.22l5.16,0a1.76,1.76,0,0,1-.34,1.12c-.2.31-.41.61-.62.92-.42.59-.86,1.17-1.3,1.74A36.68,36.68,0,0,1,3.57,23c-.64.64-1.31,1.26-2,1.88v.28l4.2,0,0-2.74,3.34-3.37.25,0V25l1.5,0v1.32l-1.55,0a4,4,0,0,0,.25,1.79c.26.58.79.67,1.35.81l0,1L4.14,30l0-1c.62-.05,1.27-.13,1.52-.79a5,5,0,0,0,.17-1.86Z" />
  </SvgIcon>
);

export default ThreeFourIcon;
